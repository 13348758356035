import React from "react";

import { createGitgraph, Orientation } from "@gitgraph/js";
import { templateExtend, TemplateName, GitgraphUserApi } from "@gitgraph/core";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import styled from "styled-components";
import { amber, blue, purple, green } from "@material-ui/core/colors";
import carreer from "./carreerData";

const template = templateExtend(TemplateName.Metro, {
  colors: [amber[500], purple[500], blue[500], green[500]],
  commit: {
    message: {
      displayAuthor: false,
      displayHash: false,
    },
  },
  branch: {
    label: {
      borderRadius: 0,
      color: "black",
      // bgColor: 'red',
    },
  },
});

const Career: React.FC = () => {
  const gitContainerRef = React.useRef<HTMLDivElement>(null);
  const [gitGraph, setGitGraph] = React.useState<GitgraphUserApi<SVGElement> | null>(null);
  // const isDesktop = useMediaQuery("(min-width:900px)");

  React.useEffect(() => {
    if (!gitGraph && gitContainerRef.current) {
      const gitGraph = createGitgraph(gitContainerRef.current, {
        // orientation: isDesktop ? Orientation.VerticalReverse : Orientation.HorizontalReverse,
        orientation: Orientation.VerticalReverse,
        author: "bcorne@localhost",
        branchLabelOnEveryCommit: true,
        template: template,
        responsive: true,
      });
      setGitGraph(gitGraph);
      carreer(gitGraph);
    }
  }, [gitGraph, gitContainerRef]);

  return (
    <Container>
      <AppBar position="absolute">
        <Toolbar>
          <Button href="/" color="inherit">
            <ChevronLeft />
          </Button>
          <Typography variant="h6">Carreer</Typography>
        </Toolbar>
      </AppBar>
      {/* force remounting gitgraph by specifying different key for desktop/non-desktop */}
      <GitContainer ref={gitContainerRef} />
      <StyledFooter>
        <div>
          Created using GitGraph.js
        </div>
      </StyledFooter>
    </Container>
  );
};

const Container = styled.div`
  overflow: auto;
  height: 100vh;
`;

const GitContainer = styled.div`
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: auto;
  overflow-y: auto;
  font-family: Consolas, "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  foreignObject > p {
    font-family: Consolas, "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
    color: white; // overwrite body part of commit
  }
  padding-bottom: 100px !important;
`;

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
`; 

export default Career;
