import { GitgraphUserApi } from "@gitgraph/core";

const carreerData = (Gitgraph: GitgraphUserApi<SVGElement>) => {
  const master = Gitgraph.branch("master");

  master.commit("Hello, World!").tag("1988-08-13");

  const studies = Gitgraph.branch("studies");

  studies.commit("ASO Science&Math @ H.A. Karel Buls, Brussels").tag("2006-06-30");

  studies.commit("Bachelor Sciences: Computer Science @ VUB, Brussels").tag("2011-06-31");

  studies.commit("Thesis: Making existing games adaptive using AOP @ VUB, Brussels");

  studies.commit("Master Engineering: Computer science @ VUB, Brussels");

  master.merge(studies).tag("2013-06-30");

  const stratics = Gitgraph.branch("stratics");
  stratics
    .commit("ICT Engineer @ Stratics")
    .tag("2013-07-01")
    .tag("first-job");

  master.merge(stratics, "Merge branch stratics").tag("2014-09-01");

  const adneom = Gitgraph.branch("adneom");
  adneom.commit("IT Consultant @ Adneom").tag("2014-11-01");

  adneom.commit("People management angular webapp @ Adneom");

  adneom.commit("People management ionic app @ Adneom");

  adneom.commit("Test automation @ Colihop");

  adneom.commit("Sectorial planning tool @ Ministery of sustainable dev & infra (LU)")

  const seaters = adneom.branch("seaters");
  seaters.commit("Front-End Dev @ Seaters").tag("2015-03-01");

  master.merge(adneom, "Merge adneom").tag("2015-09-01");

  seaters.commit("FullStack Dev @ Seaters").tag("2015-09-02");

  master.commit("Founded Bennit").tag("2015-12-13").tag("owner");

  // stratics.commit("Front-End Architect").tag("2015-12-14");
  stratics.commit("mymip.be v2.0").tag("2016-02-28");

  // master.merge(stratics).tag("2016-04-01");

  seaters.commit("Promotion: Technical Lead").tag("2016-11-01");

  const crazygames = master.branch("crazygames");
  crazygames.commit("Ops and Front-End Dev").tag("2017-06-01");

  crazygames.commit("Gameframe v1.0").tag("2017-08-01");

  master.merge(seaters, "Merge branch seaters").tag("2017-11-01");
  
  crazygames.commit("Promotion: CTO").tag("2017-11-02");
  
  const guardis = master.branch("guardis");
  guardis.commit("FullStack Dev @ Guardis").tag("2017-10-30");


  guardis.commit("comodit-agent improvements (Python)");

  crazygames.commit("Developer portal v1.0").tag("2018-02-01");

  guardis.commit("my.comodit.com dashboard");

  master.merge(guardis).tag("2019-04-01");

  master.commit("Hello, Siebren!").tag("2019-06-13");

  crazygames.commit("Crazygames.com v2.0").tag("2019-08-01");

  master.merge(crazygames, "Merge branch crazygames").tag("2019-08-31");

  // stratics.commit("Front-End Dev").tag("2019-09-01");

  stratics.commit("Selector tool v1.0").tag("2019-11-01");

  stratics.commit("Selector tool v2.0").tag("2020-03-01");
  
  master.merge(stratics).tag("2020-05-31");

  const vrt = master.branch("vrt");
  vrt.commit("Fullstack Dev @ Ketnet").tag("2020-06-01");

  vrt.commit("meisjes.ketnet.be").tag("2020-09-01");

  vrt.commit("ketnet senior app v1.0").tag("2020-10-01");

  vrt.commit("ketnet senior app v2.0").tag("2021-01-01");

  vrt.commit("ketnet.be v2.0").tag("2021-01-04");

  vrt.commit("vrtnu TV app - graphql mentor");

  vrt.commit("ketnet games SDK for fotofabriek").tag("2021-05-01");

  master.merge(vrt).tag("2021-09-30");

  const playco = master.branch("playco");
  playco.commit("Senior Game Engineer").tag("2021-10-01");
  
  playco.commit("Features for 'trip royale' game");

  playco.commit("MVP 'pets' game");

  playco.commit("Hackaton 'cooks & cards' game");

  master.merge(playco).tag("2022-02-28");

  master.tag({
    name: 'HEAD',
    style: {
      bgColor: 'red',
    }
  });

  master.commit("So long and thanks for all the fish!");
};

export default carreerData;
