import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './Home';
import Career from './Carreer';
import GlobalStyles from './GlobalStyles';

function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Switch>
          <Route path="/career">
            <Career />
          </Route>
          <Route path="*">
            <Home />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
