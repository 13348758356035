import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

const Home: React.FC = () => (
  <Container>
    <Logo
      src="/images/square-logo_640x640.png"
      alt="logo"
    />
    <Title>
      Bennit.be
      <br />
      IT Consulting
    </Title>
    <Separator />
    <ul>
      <li>
        <Link to="/career">
          <GitCommand>git@bennit.be:bcorne/career</GitCommand>
        </Link>
      </li>
    </ul>
    <Separator />
    <Address className="Home-address">
      Lareveenstraat 32
      <br />
      1980 Zemst
      <br />
      Belgium
      <br />
      <br />
      VAT: BE 0750.530.372
    </Address>
    <Separator />
    <Copyright>
      &copy; Copyright 2015 - {new Date().getFullYear()}
      <br />
    </Copyright>
  </Container>
);

const Container = styled.div`
  text-align: center;
  a {
    color: inherit;
    text-decoration: none !important;
  }
  ul {
    list-style: none;
  }

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const Logo = styled.img`
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 16px;
  z-index: 0;
  animation:
      // Spin infinite 20s linear,
      Fade infinite 4s linear;
`;

const Title = styled.h1`
  z-index: 99;
  font-size: 2rem;
  margin: 1rem;
  color: #61dafb;
  padding: 0;
`;

const Separator = styled.hr`
  width: 40vw;
`

const Address = styled.p`
  padding: 1rem;
  font-size: 1rem;
`;

const Copyright = styled.p`
  padding: 1rem;
  font-size: 0.7rem;
`;

const GitCommand = styled.pre`
  padding: 1rem;
  max-width: 100vw;
  white-space: pre-wrap;
  word-break: break-word;
`;

export default Home;
